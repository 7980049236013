import styled from "styled-components";

export const Wrapper = styled.ul`
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	li {
		a,
		a:visited,
		a:active,
		a:hover {
			margin: 10px;
			color: inherit;
			padding: 5px;
		}
	}
`;
