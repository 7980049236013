import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { Wrapper } from "./style";
import { CtaLink } from "../../Elements";

const SocialLinks = ({ socialLinks }) => {
	const socialIcons = {
		facebook: faFacebookF,
		twitter: faTwitter,
		instagram: faInstagram,
		linkedIn: faLinkedin,
	};
	const renderLinks = () => {
		const keys = Object.entries(socialLinks);
		return keys.map(([socialType, { link, type, target }], index) => {
			if (link) {
				return (
					<li key={index}>
						<CtaLink
							Link={link}
							type={type}
							value={link}
							target={target}
							className="link"
						>
							<FontAwesomeIcon
								icon={socialIcons[socialType]}
								className={"hamburgerIcon"}
								size={"2x"}
							/>
						</CtaLink>
					</li>
				);
			} else {
				return null;
			}
		});
	};

	return <Wrapper>{renderLinks()}</Wrapper>;
};

export default SocialLinks;
